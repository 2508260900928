import { useEffect, useState } from "react";
import Footer from "./components/Footer/Footer";
import Loader from "./components/Loader/Loader";
import DashboardContainer from "./containers/DashboardContainer";
import { getUser } from "./core/api";
import { ACCESS_TOKEN_KEY } from "./core/auth";
import { RoutesEnum } from "./core/enums";
import { setUser } from "./core/store/user/userSlice";
import { IRoute } from "./core/types";
import { useAppDispatch, useAppSelector } from "./hooks/useReduxToolkit";
import { useScrollTopOnRouteChange } from "./hooks/useScrollTopOnRouteChange";
import ConfirmAccount from "./pages/ConfirmAccount/ConfirmAccount";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Login from "./pages/Login/Login";
import Page404 from "./pages/Page404";
import ResetPassword from "./pages/ResetPassword/ResetPassword";
import SignUp from "./pages/SignUp/SignUp";
import Routes from "./Routes";

const Page404Route: IRoute = {
  path: "*",
  redirectTo: "/"
};

const publicRoutes: IRoute[] = [
  {
    path: "/",
    component: Login,
    exact: true,
  },
  {
    path: RoutesEnum.ForgotPassword,
    component: ForgotPassword,
  },
  {
    path: RoutesEnum.ResetPassword,
    component: ResetPassword,
  },
  {
    path: RoutesEnum.SignUp,
    component: SignUp,
  },
  {
    path: RoutesEnum.ConfirmAccount,
    component: ConfirmAccount
  },
  {
    path: "/",
    redirectTo: "/",
  },
];

const privateRoutes: IRoute[] = [
  {
    path: "/",
    component: DashboardContainer,
  },
];

export default function AppRoutes() {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const user = useAppSelector((s) => s.user.user);

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN_KEY)) {
      getUser()
        .then((user) => {
          setIsLoading(false);
          dispatch(setUser(user));
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }, [dispatch]);

  useScrollTopOnRouteChange();

  const curRoutes = [...(user ? privateRoutes : publicRoutes), Page404Route];

  if (isLoading) return <Loader />;

  return (
    <div className="min-vh-100 d-flex flex-column">
      <Routes routes={curRoutes} />
      <Footer />
    </div>
  );
}
