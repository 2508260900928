import { createSlice } from '@reduxjs/toolkit';

interface User {
  first_name: string;
  email: string;
  last_name: string;
  company_name: string;
  password: string;
  user_id: string;
  subscription_status: string;
  trial_days_left?: number;
}
interface UserState {
  user: User | undefined;
}

const initialState: UserState = {
  user: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (
      state,
      action: {
        payload: User | undefined;
      }
    ) => {
      state.user = action.payload;
    },
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;
