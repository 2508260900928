import RichTextEditor from "../../components/RichTextEditor/RichTextEditor1";
import { ContentItem } from "./Creator";
import { ReactComponent as ArrowLeft } from "../../icons/arrow-left.svg";
import { useEffect, useMemo, useRef, useState } from "react";
import { ChangeEventHandler, ChangeEvent, MouseEventHandler, MouseEvent } from "react";
import "./CustomTemplate.scss"

interface Props {
  onClickBack: () => void;
  onClickStartOver: () => void;
  onClickGenerate: () => void;
  allFields: any;
  text: string;
  onChangeText: (text:string) => void;
  generateButtonStatus: boolean;
  setGenerateButtonStatus:(value:boolean) => void;
}

export default function CustomTemplates({
  onClickBack,
  onClickStartOver,
  onClickGenerate,
  allFields,
  text,
  onChangeText,
  generateButtonStatus,
  setGenerateButtonStatus
}: Props) {
  // console.log(notimported);
  // console.log(imported_fields);

  const handleChangeofText = (value:string) =>
  {
    if (value.length > 50){
      setGenerateButtonStatus(true);
    }
    else{
      setGenerateButtonStatus(false);
    }
    onChangeText(value);
  }

  const quillRef:any = useRef(null);

  const insertText = ((text: any) => {
    let quillEditor = quillRef?.current.getEditor();
    let range = quillEditor.getSelection();
    let position = range ? range.index : 0;
    quillEditor.insertText(position, text);
  });

  return (
    <div>
      <div className="mb-4 pb-2 align-items-center text-center justify-content-between">
        <>
        <span> Type your custom templates in your voice and we will use generative AI to rewrite the articles using your templates.  To add variables, simply click the variable button and we will insert it. It’s limited to articles that have fewer than 2000 words or 100 rows in the excel file.</span>
        </>
      </div>
      <div className="mb-4 pb-2">
      <RichTextEditor 
        text={text} 
        onChange={handleChangeofText}
        quillRef={quillRef}
        />
      </div>
      <div className="small-button-wrapper">
      {allFields?.map((key: any) => 
              <button className='small-button' type="button" onClick={(e: MouseEvent<HTMLButtonElement>) => {insertText("[" + key.label + "]")}}>{key.label} </button>
        )}
      </div>

      <div className="small-button-wrapper">

      </div>
      <div className="d-flex align-items-center justify-content-between">
        <button
          className="Creator__back p-0 bg-transparent border-0 fw-bold text--primary"
          onClick={onClickBack}
        >
          <ArrowLeft className="me-2" />
          Back
        </button>
        <div className="d-flex align-items-center justify-content-end">
          <button
              style={{
                width: 120,
                padding: "10px 10px",
                margin: "10px",
                background: "#415473"
  
              }}
              className="btn"
              onClick={onClickStartOver}
            >
              Start Over
            </button>

          <button
              style={{
                width: 130,
                padding: "10px 10px",
              }}
              className="btn btn--orange"
              onClick={onClickGenerate}
              disabled={!generateButtonStatus}
            >
              Generate
            </button>
        </div>
      </div>

    </div>
  );
}

