import React, { useState } from 'react'
import { type FormikErrors, useFormik } from 'formik'
import { Link, useHistory } from 'react-router-dom'

import { useAppDispatch } from '../../hooks/useReduxToolkit'
import { toast } from 'react-toastify'

import LoginContainer from '../../containers/LoginContainer'

import Input from '../../components/Input/Input'
import Loader from '../../components/Loader/Loader'

import { login, setTokensToLocalStorage } from "../../core/auth";
import { RoutesEnum } from '../../core/enums'
import { getUser, confirmAccount } from '../../core/api'
import { setUser } from "../../core/store/user/userSlice";
import { EMAIL_KEY, PASSWORD_KEY } from "../../core/auth"

interface FormikValues {
  code: string
}

export interface ConfirmActInput {
  email: string|null;
  password: string|null;
  code: string;
}

export default function ConfirmAccount (): React.ReactElement {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [isLoading, setIsLoading ] = useState(false);

  const formik = useFormik({
    initialValues: {
      code: ''
    },
    validate: (values) => {
      const errors: FormikErrors<FormikValues> = {}
      if (values.code.length === 0) {
        errors.code = 'Required'
      }

      return errors
    },
    onSubmit: (values) => {
      setIsLoading(true);
      const payload: ConfirmActInput = {
        code: values.code,
        email: localStorage.getItem(EMAIL_KEY) ,
        password: localStorage.getItem(PASSWORD_KEY)
      }
      confirmAccount(payload)
      .then((res) => {
        setIsLoading(true);
        let email = localStorage.getItem(EMAIL_KEY);

        if (!email){
          email = "test@email"
        }

        let password = localStorage.getItem(PASSWORD_KEY);

        if (!password){
          password = "testpassword"
        }
        return login({email: email, password: password})})
        .then((res) => {
            setTokensToLocalStorage(
              res.AuthenticationResult!.AccessToken!,
              res.AuthenticationResult!.RefreshToken!,
              res.AuthenticationResult!.IdToken!
            );
            localStorage.removeItem(EMAIL_KEY);
            localStorage.removeItem(PASSWORD_KEY);

          return getUser();
        })
        .then((res) => {
          dispatch(setUser(res));
        })
        .catch((err) => {
            setIsLoading(false);
            console.error(err);
            toast.error("Confirm Account Failed" + err.message, {
              position: "top-right",
              autoClose: 4500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              theme: "dark",
            });
        })
        .finally(() => {
        history.push("/")
        setIsLoading(false);
    });
    },
  });

  return (

    <LoginContainer>
      { isLoading && <Loader /> }
      <div className='ResetPassword_card Login__card px-3 d-flex flex-column align-items-center justify-content-center w-100'>
        <div className='Login__title fw-bold mb-2'>Account Verification</div>
        <div className="mb-4 Login__subtitle">Please verify your account using the code we’ve sent to your email</div>
        <form className='w-100 Login__form' onSubmit={formik.handleSubmit}>
          <div className='d-flex'>
            <Input
              onBlur={formik.handleBlur}
              value={formik.values.code}
              onChange={formik.handleChange}
              name='code'
              label='Code'
              className='mb-4 flex-grow-1'
              type='text'
              required
              error={formik.touched.code}
            />
          </div>
          <div className='d-flex flex-column align-items-center justify-content-center button-container'>
            <button className='btn w-100 Login__btn ' type='submit'>
              Confirm
            </button>

            <div className="text-end fw-bold mt-1 mb-1">
              <Link to='/'>Return to login</Link>
            </div>
            <div>
              New User?{' '}
              <Link to={RoutesEnum.SignUp} className="fw-bold">
                Sign Up!
              </Link>
            </div>
          </div>
        </form>
      </div>
    </LoginContainer>
  );
}
