import './PreviousArticles.scss';
import classNames from 'classnames';
import { useState } from 'react';
import PostBox from '../../components/PostBox/PostBox';
import { ReactComponent as OrderIcon } from '../../icons/order.svg';
import { getCreatorCategories, getPreviousArticles } from '../../core/api';
import Loader from '../../components/Loader/Loader';
import { useAppSelector } from '../../hooks/useReduxToolkit';
import { ITEMS_MAP } from '../Dashboard/Dashboard';
import { useQueriesTyped } from '../../hooks/useQueryTyped';

export default function PreviousArticles() {
  const [order, setOrder] = useState<'asc' | 'desc'>('desc');
  const [activeTab, setActiveTab] = useState('all');
  const user = useAppSelector((s) => s.user.user);

  const [categories, articles] = useQueriesTyped([
    {
      queryKey: ['Categories', user!.user_id],
      queryFn: getCreatorCategories,
    },
    {
      queryKey: ['PreviousArticles', user?.user_id],
      queryFn: getPreviousArticles,
    },
  ]);

  if (categories.isLoading || articles.isLoading) return <Loader />;

  return (
    <div className='PreviousArticles '>
      <div className='container PreviousArticles__container'>
        <div className='PreviousArticles__title fw-bold mb-4 text-center fw-bold'>
          Previous Articles
        </div>
        <div className='row row-cols-auto justify-content-between mb-5'>
          {['all', ...(categories.data || [])].map((f) => (
            <div className='col' key={f}>
              <button
                className={classNames(
                  'p-0 bg-transparent border-0 text--primary PreviousArticles__tab',
                  {
                    'fw-bold': activeTab === f,
                  }
                )}
                onClick={() => {
                  setActiveTab(f);
                }}
              >
                {f === 'all' ? 'All' : ITEMS_MAP[f]?.label || f}
              </button>
            </div>
          ))}
        </div>
        <button
          className='d-flex align-items-center ms-auto mb-4 bg-transparent p-0 border-0 PreviousArticles__orderBtn transition-color'
          onClick={() => {
            setOrder((prev) => (prev === 'desc' ? 'asc' : 'desc'));
          }}
        >
          <div className='me-3'>
            {order === 'desc' ? 'Newst to Oldest' : 'Oldest to Newst'}
          </div>
          <div
            className={classNames('btn PreviousArticles__btn', {
              'PreviousArticles__btn--asc': order === 'asc',
            })}
          >
            <OrderIcon />
          </div>
        </button>
        {(articles.data || [])
          .sort((a, b) => {
            return order === 'desc'
              ? b.datetime.getTime() - a.datetime.getTime()
              : a.datetime.getTime() - b.datetime.getTime();
          })
          .filter((p) => p.content_type === activeTab || activeTab === 'all')
          .map((post) => (
            <div className='pb-2' key={post.asset_id}>
              <PostBox
                initialText={post.asset_body}
                className='mb-4'
                date={new Date(post.datetime)}
                category={post.content_type}
              />
            </div>
          ))}
      </div>
    </div>
  );
}
