import { Redirect, Route, Switch } from 'react-router-dom';
import { IRoute } from './core/types';

interface Props {
  routes: IRoute[];
}

export default function Routes({ routes }: Props) {
  return (
    <Switch>
      {routes.map((route) => {
        if (route.redirectTo) {
          return (
            <Route
              exact={route.exact}
              path={route.path}
              key={`redirect-${route.path}`}
            >
              <Redirect to={route.redirectTo} />
            </Route>
          );
        }
        return <Route key={route.path} {...route} />;
      })}
    </Switch>
  );
}
