import copy from "copy-to-clipboard";
import "./PostBox.scss";
import { format } from "date-fns";
import { ITEMS_MAP } from "../../pages/Dashboard/Dashboard";
import { useState, useRef } from "react";
import RichTextEditor from "../RichTextEditor/RichTextEditor1";
import classNames from "classnames";
import { toast } from "react-toastify";

interface Props {
  btns?: JSX.Element;
  initialText: string;
  category: string;
  className?: string;
  date?: Date;
  editable?: boolean;
  copyBtn?: boolean;
  externalControl?: {
    value: string;
    setValue: (v: string) => void;
  };
}

export default function PostBox({
  btns,
  initialText,
  externalControl,
  className,
  category,
  date = new Date(),
  editable = false,
  copyBtn = true,
}: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const onClickEdit = () => {
    setIsEditing(!isEditing);
  };

  const [value, setValue] = useState(initialText);
  const quillRef:any = useRef(null);

  return (
    <div className={`PostBox overflow-hidden d-flex flex-column ${className}`}>
      <div className="PostBox__header">
        <div className="d-flex justify-content-between align-items-center">
          <div>
            <div className="text--primary fw-bold">
              {ITEMS_MAP[category]?.label || "unknown"}
            </div>
            <div className="text--dark PostBox__date d-flex">
              <div className="fw-bold me-2">{format(date, "MMMM dd, yyyy")}</div>{" "}
              <div
                className="PostBox__time "
                style={{
                  paddingTop: 6,
                }}
              >
                {`${format(date, "hh:mm aaaa")}`}
              </div>
            </div>
          </div>
          <div>
            <div className="row row-cols-auto gx-3">
              {editable && (
                <div className="col">
                  <button className="btn btn--outline" onClick={onClickEdit}>
                    {isEditing ? "Confirm edit" : "Edit Text"}
                  </button>
                </div>
              )}
              {btns}
              {copyBtn && (
                <div className="col">
                  <button
                    className="btn btn--primary"
                    onClick={(event) => {
                      event.preventDefault();
                      copy(
                        externalControl?.value ? externalControl.value : value
                      );
                      toast.info("Text copied to clipboard successfully!", {
                        position: "top-right",
                        autoClose: 4500,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        theme: "dark",
                      });
                    }}
                  >
                    Copy All Text
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        className={classNames("PostBox__box overflow-hidden", {
          "p-0": isEditing,
        })}
      >
        {isEditing ? (
          <RichTextEditor
            text={externalControl?.value ? externalControl.value : value}
            onChange={
              externalControl?.setValue ? externalControl.setValue : setValue
            }
            quillRef={quillRef}
          />
        ) : (
          <div
            className="multiline Scrollbar PostBox__content"
            dangerouslySetInnerHTML={{
              __html: externalControl?.value ? externalControl.value : value,
            }}
          />
        )}
      </div>
    </div>
  );
}
