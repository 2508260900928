import { useState } from 'react';
import Checkbox from '../../components/Checkbox/Checkbox';
import './DataMappingStep.scss';
import { ReactComponent as Tip } from '../../icons/green-tip.svg';
import { ReactComponent as Cross } from '../../icons/red-cross.svg';
import { ReactComponent as ArrowLeft } from '../../icons/arrow-left.svg';
import DragableField from './DragableField';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DropBox from './DropBox';
import { Link } from 'react-router-dom';
import { ImportedState, NormalizedField } from './Creator';

interface Props {
  onClickNext: (s: ImportedState) => () => void;
  onClickBack: () => void;
  imported: ImportedState;
  setImported: React.Dispatch<React.SetStateAction<ImportedState>>;
  notImported: NormalizedField[];
  setNotImported: React.Dispatch<React.SetStateAction<NormalizedField[]>>;
  requiredFields: string[];
}

export default function DataMappingStep({
  onClickNext,
  onClickBack,
  imported,
  setImported,
  notImported,
  setNotImported,
  requiredFields,
}: Props) {
  const [checked, setChecked] = useState(false);

  const disabled = Object.values(imported).some((k) => !k.value);

  return (
    <div className='DataMappingStep'>
      <div className='mb-4 pb-2'>
      Verify that the headers in your uploaded file match the required fields.  Click, drag, and drop the items under “Your File” if they do not. 
      </div>
      <div className='mb-4'>
        <Checkbox
          checked={checked}
          onChange={(e) => {
            setChecked(e.target.checked);
          }}
          label='The first row in my file is a column header, do not import it.'
        />
      </div>
      <div className='row gx-0 mb-4 mx-0'>
        <div className='col'>
          <div className='DataMappingStep__title fw-bold'>Your File</div>
        </div>
        <div className='col'>
          <div className='DataMappingStep__title fw-bold'>Required Fields</div>
        </div>
      </div>
      <div className='d-flex mb-4 pb-2'>
        <div className='w-50 d-flex flex-column'>
          <DndProvider backend={HTML5Backend}>
            {Object.entries(imported).map(([k, v]) => {
              if (v.value) {
                return (
                  <DragableField
                    imported
                    field={v}
                    key={k}
                    onDrop={(dragTarget: NormalizedField) => {
                      if (dragTarget.label) {
                        setImported((prev) => ({
                          ...prev,
                          [k]: { ...dragTarget, label: k },
                          [dragTarget.label]: { ...v, label: dragTarget.label },
                        }));
                      } else {
                        setNotImported((prev) =>
                          prev.map((p) => {
                            if (p.initialLabel === dragTarget.initialLabel) {
                              return { ...v, label: '' };
                            }
                            return p;
                          })
                        );
                        setImported((prev) => ({
                          ...prev,
                          [v.label]: { ...dragTarget, label: v.label },
                        }));
                      }
                    }}
                  />
                );
              }

              return (
                <DropBox
                  key={k}
                  onDrop={(dragTarget: NormalizedField) => {
                    if (dragTarget.label) {
                      setImported((prev) => ({
                        ...prev,
                        [k]: { ...dragTarget, label: k },
                        [dragTarget.label]: { ...v, label: dragTarget.label },
                      }));
                    } else {
                      setNotImported((p) =>
                        p.filter(
                          (f) => f.initialLabel !== dragTarget.initialLabel
                        )
                      );
                      setImported((prev) => ({
                        ...prev,
                        [k]: { ...dragTarget, label: k },
                      }));
                    }
                  }}
                />
              );
            })}
            {notImported.map((f, i) => (
              <DragableField
                field={f}
                key={i}
                onDrop={(dragTarget: NormalizedField) => {
                  if (dragTarget.label) {
                    setImported((prev) => ({
                      ...prev,
                      [dragTarget.label]: { ...f, label: dragTarget.label },
                    }));
                    setNotImported((prev) =>
                      prev.map((p, n) => {
                        if (i === n) {
                          return {
                            ...dragTarget,
                            label: '',
                          };
                        }
                        return p;
                      })
                    );
                  } else {
                    setNotImported((prev) => {
                      const arr = [...prev];
                      arr[i] = dragTarget;
                      arr[
                        prev.findIndex(
                          (s) => s.initialLabel === dragTarget.initialLabel
                        )
                      ] = f;

                      return arr;
                    });
                  }
                }}
              />
            ))}
          </DndProvider>
        </div>
        <div className='w-50 d-flex flex-column'>
          {requiredFields.map((k) => {
            return (
              <div
                className='DataMappingStep__box d-flex flex-column justify-content-center fw-bold'
                key={k}
              >
                <div className='d-flex justify-content-between'>
                  <div>{k}</div>
                  {!!imported[k]?.value ? <Tip /> : <Cross />}
                </div>
              </div>
            );
          })}
          {!!notImported.length && (
            <div className='DataMappingStep__box DataMappingStep__box--gray flex-grow-1 fst-italic text--light'>
              Will not be used in content generation
            </div>
          )}
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-between'>
        <button
          className='Creator__back p-0 bg-transparent border-0 fw-bold text--primary'
          onClick={onClickBack}
        >
          <ArrowLeft className='me-2' />
          Back
        </button>
        <div className='d-flex align-items-center flex-grow-1 justify-content-end'>
          <Link className='Creator__cancel fw-bold opacity-50' to={'/'}>
            Cancel Import
          </Link>
          <button
            className='btn w-100 Creator__btn ms-4'
            type='submit'
            onClick={onClickNext(imported)}
            disabled={disabled}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
}
