export enum RoutesEnum {
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  SignUp = '/signup',
  Creator = '/creator/:id',
  PremiumCreator = '/premiumcreator/:id',
  PreviousArticles = '/previous-articles',
  Impact = '/impact',
  Settings = '/settings',
  Pricing = '/pricing',
  TermsConditions = '/terms-conditions',
  ConfirmAccount = '/confirm-account'
}
