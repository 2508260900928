import { ChangeEventHandler, DragEventHandler, useRef } from "react";
import { toast } from "react-toastify";
import "./FileUploader.scss";

interface Props {
  accept?: string[];
  name?: string;
  onSelect: (files: File[] | null) => void;
  className?: string;
  sizeLimit?: number;
  label?: string;
  multiple?: boolean;
}

export default function FileUploader({
  accept = ["xls", "xlsx", "csv"],
  name = "file",
  onSelect,
  className = "",
  multiple = false,
}: Props) {
  const ref = useRef<HTMLInputElement>(null);

  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    onSelect(e.target.files ? Array.from(e.target.files) : null);
    toast.info("Files selected successfully! Click Next to Upload.", {
      position: "top-right",
      autoClose: 4500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "light",
    });
  };

  const preventDefaultDragEvents: DragEventHandler<HTMLLabelElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDrop: DragEventHandler<HTMLLabelElement> = (e) => {
    preventDefaultDragEvents(e);
    onSelect(e.dataTransfer.files ? Array.from(e.dataTransfer.files) : null);
    toast.info("Drop files added successfully! Click Next to Upload.", {
      position: "top-right",
      autoClose: 4500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "light",
    });
  };

  return (
    <label
      className={`FileUploader d-flex align-items-center flex-column ${className} px-4`}
      onDrop={onDrop}
      onDragEnter={preventDefaultDragEvents}
      onDragLeave={preventDefaultDragEvents}
      onDragOver={preventDefaultDragEvents}
    >
      <div className="d-flex align-items-center mb-4 pb-2">
        <div className="FileUploader__upload fw-bold text--secondary">
          Upload File
        </div>
        <div className="ms-4 fw-bold text--secondary">...or drag file here</div>
      </div>
      <div className="mb-4 pb-2 text-center">
        Only Excel (.xls .xlsx) and .csv files are supported.
      </div>
      <input
        name={name}
        type="file"
        onChange={onChange}
        className="d-none"
        accept={accept.map((str) => `.${str}`).join(", ")}
        ref={ref}
        multiple={multiple}
      />
    </label>
  );
}
