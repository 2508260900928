import { ChangeEventHandler } from 'react';
import cn from 'classnames';
import './Checkbox.scss';
import { ReactComponent as TipIcon } from '../../icons/tip_white.svg';
import classNames from 'classnames';

export enum CheckboxEnum {
  Checkbox,
  Radio,
}

interface Props {
  id?: string;
  name?: string;
  checked: boolean;
  disabled?: boolean;
  type?: CheckboxEnum;
  onChange: ChangeEventHandler<HTMLInputElement>;
  className?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  isError?: boolean;
  labelId?: string;
  label: JSX.Element | string;
}

const Checkbox = ({
  type = CheckboxEnum.Checkbox,
  checked,
  onChange,
  onBlur,
  className = '',
  isError = false,
  disabled = false,
  id,
  name,
  label,
  labelId = '',
}: Props) => {
  const isCheckbox = type === CheckboxEnum.Checkbox;

  return (
    <div className={`Checkbox ${className}`}>
      <label
        className={cn('d-flex align-items-center Checkbox__container', {
          Checkbox__error: isError,
        })}
        data-testid={name}
      >
        <input
          id={id}
          name={name}
          type={isCheckbox ? 'checkbox' : 'radio'}
          onChange={onChange}
          checked={checked}
          disabled={disabled}
          onBlur={onBlur}
          data-testid={'checkbox_' + name}
        />
        <div
          className={cn('Checkbox__input', {
            'Checkbox__input--radio': !isCheckbox,
            'Checkbox__input--checkbox': isCheckbox,
          })}
        >
          {isCheckbox && <TipIcon />}
        </div>
        <div
          className={classNames('Checkbox__label', {
            'opacity-50': !checked,
          })}
          id={labelId}
        >
          {label}
        </div>
      </label>
    </div>
  );
};

export default Checkbox;
