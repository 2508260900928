import { FormikErrors, useFormik } from 'formik';
import Input from '../../components/Input/Input';
import LoginContainer from '../../containers/LoginContainer';
import './ResetPassword.scss';

interface FormikValues {
  password: string;
  confirm_password: string;
}

export default function ResetPassword() {
  const formik = useFormik({
    initialValues: {
      password: '',
      confirm_password: '',
    },
    validate: (values) => {
      const errors: FormikErrors<FormikValues> = {};
      if (!values.confirm_password) {
        errors.confirm_password = 'Required';
      }
      if (!values.password) {
        errors.password = 'Required';
      }
      return errors;
    },
    onSubmit: (values) => {},
  });

  return (
    <LoginContainer>
      <div className='ResetPassword__card Login__card px-3 d-flex flex-column align-items-center justify-content-center w-100'>
        <div className='Login__title fw-bold mb-2'>Reset Password</div>
        <div className='mb-4 pb-1'>lorem ipsum dolor about how this works.</div>
        <form className='w-100 Login__form' onSubmit={formik.handleSubmit}>
          <div className='d-flex'>
            <Input
              onBlur={formik.handleBlur}
              value={formik.values.password}
              onChange={formik.handleChange}
              name='password'
              label='New Password'
              className='mb-4 flex-grow-1'
              type='password'
              required
              error={formik.touched.password}
            />
          </div>
          <Input
            onBlur={formik.handleBlur}
            value={formik.values.confirm_password}
            onChange={formik.handleChange}
            name='confirm_password'
            label='Confirm New Password'
            className='mb-5'
            type='password'
            required
            error={formik.touched.confirm_password}
          />
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <button className='btn w-100 Login__btn' type='submit'>
              Reset
            </button>
          </div>
        </form>
      </div>
    </LoginContainer>
  );
}
