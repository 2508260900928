import { Link } from 'react-router-dom';
import FileUploader from '../../components/FileUploader/FileUploader';
import { ReactComponent as ArrowRightIcon } from '../../icons/arrow-right.svg';
import { downloadFile } from '../../core/helpers';
import './UploadStep.scss';
import { useState } from 'react';
import { CreatorConfig } from './Creator';
interface Props {
  onSubmitFile: (f: File) => void;
  data: CreatorConfig;
  id: string;
}

export default function UploadStep({ onSubmitFile, data, id }: Props) {
  const [file, setFile] = useState<File>();

  const onSelect = (files: File[] | null) => {
    if (!files) return;
    setFile(files[0]);
  };

  const getCsvPath = () => {
    const csvs = ['crime', 'marriage_certificates', 'death_notice', 'real_estate', 'weather', 'births']
    if (csvs.includes(id)) {
      return `/${id}.csv`;
    }
    return '/template.csv';
  }

  const getFilename = (str: string) => {
    const name = str.split('_').map(s => `${s.charAt(0).toUpperCase()}${s.slice(1)}`).join('')
    return `LedeAI-${name}-Template.csv`
  }

  return (
    <div>
      <div
        style={{
          paddingRight: 36,
        }}
      >
        <div className='d-flex'>
          <div
            className='Creator__content'
            dangerouslySetInnerHTML={{
              __html: data.upload_sidebar_text,
            }}
          />

          {/* <div className='Creator__content'>
            <div className='mb-4 pb-2'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut et
              massa mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
              fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
              vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum
              auctor ornare leo, non suscipit magna interdum eu
            </div>
            <div className='fw-bold'>Lorem ipsum dolor sit amet</div>
            <div className='mb-2'>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </div>
            <div className='mb-4 pb-2'>
              <button
                className='Creator__link fw-bold p-0 bg-transparent border-0 text-decoration-underline transition-color'
                onClick={() => downloadFile('/template.csv', 'template.csv')}
              >
                download something link
              </button>
            </div>
            <div className='fw-bold'>Lorem ipsum dolor sit amet</div>
            <div className='mb-4 pb-2'>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in
              hendrerit urna. Pellentesqu
            </div>
            <div className='fw-bold'>Lorem ipsum dolor sit amet</div>
            <div>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur adipiscing elit. Ut et massa mi. Aliquam in
              hendrerit urna. Pellentesqu
            </div>
          </div> */}
          <div className='ms-5 flex-grow-1'>
            <div className='UploadStep__box mb--80'>
              <FileUploader onSelect={onSelect} />
              <div className='px-4'>
                <div className='mb-2 text-center'>
                  No file? Use our template to get started
                </div>
                <button
                  className='fw-bold p-0 border-0 bg-transparent d-flex align-items-center transition-color FileUploader__link mx-auto'
                  onClick={(e) => {
                    downloadFile(getCsvPath(), getFilename(id));
                  }}
                >
                  Template File <ArrowRightIcon className='ms-1' />
                </button>
              </div>
            </div>
            <div className='text-center'>
              <button
                className='btn w-100 Creator__btn mb-4'
                type='submit'
                onClick={() => onSubmitFile(file!)}
                disabled={!file}
              >
                Next
              </button>
              <div className='text-center'>
                <Link className='Creator__cancel fw-bold opacity-50' to={'/'}>
                  Cancel Import
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
