import { IImpact, NormalizedImpact } from '../pages/Impact/Impact';
import { NormalizedPost, Post } from './types';

export function normalizePost(v: Post): NormalizedPost {
  return {
    ...v,
    datetime: new Date(v.datetime),
  };
}

export const normalizeImpact = (d: IImpact): NormalizedImpact => {
  return {
    articles: {
      'All Time': d.articles_all_time,
      'This Year': d.articles_this_year,
      Yesterday: d.articles_yesterday,
    },
    company_name: d.company_name,
    savings: {
      'Time Saved*': d.hours_saved,
      'Money Saved**': d.money_saved,
    },
  };
};
