import { Link, NavLink } from 'react-router-dom';
import { useState } from 'react';
import { RoutesEnum } from '../../core/enums';
import { ReactComponent as Logo } from '../../icons/logo-header.svg';
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import './Header.scss';

import { logOutUser } from '../../core/auth';


const LINKS = [
  {
    label: 'Home',
    href: '/',
  },
  {
    label: 'Previous Articles',
    href: RoutesEnum.PreviousArticles,
  },
  {
    label: 'Settings',
    href: RoutesEnum.Settings,
  },
];

interface Props {
  bannerText?: string;
  bannerButtonText?: string;
  bannerVisible?: boolean;
  billingUrl?: string;
}

export default function Header({
 bannerText = '',
 bannerButtonText='Start Free Trial',
 bannerVisible = false,
 billingUrl = process.env.REACT_APP_STRIPE_URL
}: Props) {

  const [isBannerVisible, setIsBannerVisible] = useState(bannerVisible);

  const handleCloseBanner = () => {
    setIsBannerVisible(false);
  };

  const handleLogout = (e: any): void => {
    const res = logOutUser();
    e.stopPropagation()
    try {
    } catch (error) { }
    window.location.reload()
  }

  return (
    <>
    <div className='Header d-flex'>
      <div className='container d-flex align-items-center justify-content-between h-100 Header__container'>
        <Link to='/'>
          <Logo />
        </Link>
        <div className='row row-cols-auto'>
          {LINKS.map((l) => (
            <div className='col flex-shrink-0' key={l.label}>
              <NavLink to={l.href} className='fw-bold text-decoration-none'>
                {l.label}
              </NavLink>
            </div>
          ))}
          <div className="col-12 col-lg flex-shrink-0">
            <a
              onClick={handleLogout}
              className="fw-bold text-decoration-none link-white"
            >
              Log Out
            </a>
          </div>
        </div>
      </div>
    </div>
    {isBannerVisible && (
    <div className='Trial d-flex'>
    <div className='container d-flex align-items-center justify-content-between h-100 Trial__container'>
      <span> {bannerText}</span>
      <button onClick={(event) => 
        window.open(billingUrl, '_blank')
        }>{bannerButtonText}</button>
      <CloseIcon onClick={handleCloseBanner}/>
    </div>
    </div>
    )}
    </>
  );
}
