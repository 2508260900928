import "./Impact.scss";
import AnimatedNumber from "./AnimatedNumber";
import { getImpact } from "../../core/api";
import { useQuery } from "react-query";
import { useAppSelector } from "../../hooks/useReduxToolkit";
import Loader from "../../components/Loader/Loader";

export interface IImpact {
  articles_all_time: number;
  articles_this_year: number;
  articles_yesterday: number;
  company_name: string;
  hours_saved: number;
  money_saved: number;
}

export interface NormalizedImpact {
  articles: {
    "All Time": number;
    "This Year": number;
    Yesterday: number;
  };
  company_name: string;
  savings: {
    "Time Saved*": number;
    "Money Saved**": number;
  };
}

export default function Impact() {
  const user = useAppSelector((s) => s.user.user);
  const { data, isLoading } = useQuery(["Impact", user!.user_id], getImpact);

  if (isLoading) return <Loader />;

  return (
    <div className="Impact">
      <div className="Impact__container container">
        <div className="text--orange fw-bold text-center">Impact</div>
        <div
          className="Dashboard__title fw-bold text-center"
          style={{
            marginBottom: 170,
          }}
        >
          {data?.company_name}
        </div>
        <div className="mb-4 Impact__text fw-bold text--primary">
          Articles Published
        </div>
        <div>
          <div className="row pb--70">
            {data?.articles &&
              Object.entries(data.articles).map(([k, v]) => (
                <div className="col" key={k}>
                  <div className="Impact__box d-flex flex-column align-items-center justify-content-center">
                    <div className="Impact__label text--primary fw-bold mb-2">
                      {k}
                    </div>
                    <div className="Impact__count text--secondary fw-bold">
                      <AnimatedNumber
                        value={v >= 1000 ? v / 1000 : v}
                        snapValue={
                          Number.isInteger(v >= 1000 ? v / 1000 : v)
                            ? undefined
                            : 0.1
                        }
                      />
                      {v >= 1000 && "K"}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
        {data?.savings && (
          <>
            <div
              className="mb-4 Impact__text fw-bold text--primary Impact__border"
              style={{
                paddingTop: 80,
              }}
            >
              Savings
            </div>
            <div>
              <div className="row pb--70 row-cols-3">
                {Object.entries(data.savings).map(([k, v]) => (
                  <div className="col" key={k}>
                    <div className="Impact__box d-flex flex-column align-items-center justify-content-center">
                      <div className="Impact__label text--primary fw-bold mb-2">
                        {k}
                      </div>
                      <div className="Impact__count text--secondary fw-bold">
                        <AnimatedNumber
                          value={v >= 1000 ? v / 1000 : v}
                          snapValue={
                            Number.isInteger(v >= 1000 ? v / 1000 : v)
                              ? undefined
                              : 0.1
                          }
                        />
                        {v >= 1000 && "K"}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="fst-italic text--primary">
              *We assume it takes 10 minutes for a human to write each Lede AI
              article 10 min * number
            </div>
            <div className="mb-2 fst-italic text--primary">
              of articles published / 60 = # Hours
            </div>
            <div className="fst-italic text--primary">
              **We assume each journalist's salary + benefits totals
              $40,000/year
            </div>
            <div className="fst-italic text--primary">20*# Hours</div>
          </>
        )}
      </div>
    </div>
  );
}
