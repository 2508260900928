import "./Settings.scss";
import { useState } from 'react';
import { useAppSelector } from '../../hooks/useReduxToolkit';
import AccountSetting from './AccountSetting';
import BillingSetting from './BillingSetting';
import UsersSetting from './UsersSetting';
import PlanSetting from './PlanSetting';

// const Menu = [
//   "Account",
//   "Billing",
//   "Users",
//   "Plan",
// ]

export default function Settings() {
  const [tab, setTab] = useState(0)

  const userData = useAppSelector(s => s.user.user)
  let subState = null
  let billingUrl = process.env.REACT_APP_STRIPE_BILLING_URL

  subState = userData?.subscription_status

  if (subState === 'inactive' || subState === 'canceled' || subState === 'notvalid') {
    billingUrl = process.env.REACT_APP_STRIPE_URL
  }


  const Menu = [
    {
      label: "Account",
      component: <AccountSetting />,
    },
    {
      label: "Billing",
      component: <BillingSetting />
    }
  ]

  return (
    <div className="settings">
      <div className="setting-menu">
        <h5>Settings</h5>
        <ul>
          {
            Menu.map((item, i) =>
              <li
                className={i === tab ? 'active' : ''}
                onClick={() => {i === 1 ? window.open(billingUrl, '_blank'): setTab(i) }}
              >
                {item.label}
              </li>
            )
          }
        </ul>
      </div>
      <div className="setting-content">
        <h5 className="subtitle">Settings</h5>
        <h3 className="title">{Menu[tab].label}</h3>
        {
          Menu[tab].component
        }
      </div>
      <div className="setting-right"></div>
    </div>
  );
}
