import { useFormik } from 'formik';
import Input from '../../components/Input/Input';

export interface BillingInput {
    name: string;
    family_name: string;
    email: string;
    company_name: string;
    city: string;
    state: string;
    zip_code: string;
}

export default function BillingSetting() {
    const formik = useFormik<BillingInput>({
        initialValues: {
            name: '',
            family_name: '',
            email: '',
            company_name: '',
            city: '',
            state: '',
            zip_code: '',
        },
        onSubmit: (values) => {

        },
    });
    return (
        <div className='billing-setting'>
            <h5 className='billing-title'>Billing Info</h5>
            <form className='w-100 Login__form ' onSubmit={formik.handleSubmit}>
                <Input
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    name='name'
                    label='Name'
                    placeholder="John Doe"
                    className='mb-4 light'
                    error={formik.touched.name && formik.errors.name}
                />
                <Input
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    name='email'
                    label='Email'
                    placeholder='example@mail.com'
                    className='mb-4 light'
                    error={formik.touched.email && formik.errors.email}
                />
                <div className="d-flex" style={{ gap: '25px' }}>
                    <Input
                        onBlur={formik.handleBlur}
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        name='card'
                        label='Card'
                        placeholder='Visa Ending in 4432'
                        className='mb-2 light'
                        required={true}
                        error={formik.touched.city && formik.errors.city}
                    />
                    <Input
                        onBlur={formik.handleBlur}
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        name='expiry'
                        label='Expiry'
                        placeholder='01/28'
                        className='mb-2 light'
                        required={true}
                        error={formik.touched.state && formik.errors.state}
                        style={{ width: 94 }}
                    />
                    <Input
                        onBlur={formik.handleBlur}
                        value={formik.values.zip_code}
                        onChange={formik.handleChange}
                        name='cvv'
                        label='CVV'
                        placeholder='***'
                        className='mb-2 light'
                        required={true}
                        error={formik.touched.zip_code && formik.errors.zip_code}
                        style={{ width: 88 }}
                    />
                </div>
                <Input
                    onBlur={formik.handleBlur}
                    value={formik.values.company_name}
                    onChange={formik.handleChange}
                    name='company_name'
                    label='Address'
                    placeholder='1234 Main St.'
                    className='mb-2 light'
                    error={formik.touched.company_name && formik.errors.company_name}
                />
                <Input
                    onBlur={formik.handleBlur}
                    value={formik.values.company_name}
                    onChange={formik.handleChange}
                    name='company_name'
                    placeholder='Unit 100'
                    className='mb-4 light'
                    error={formik.touched.company_name && formik.errors.company_name}
                />
                <div className="d-flex" style={{ gap: '25px' }}>
                    <Input
                        onBlur={formik.handleBlur}
                        value={formik.values.city}
                        onChange={formik.handleChange}
                        name='city'
                        label='City'
                        placeholder='Big City'
                        className='mb-2 light'
                        required={true}
                        error={formik.touched.city && formik.errors.city}
                    />
                    <Input
                        onBlur={formik.handleBlur}
                        value={formik.values.state}
                        onChange={formik.handleChange}
                        name='state'
                        label='State'
                        placeholder='OH'
                        className='mb-2 light'
                        required={true}
                        error={formik.touched.state && formik.errors.state}
                        style={{ width: 87 }}
                    />
                    <Input
                        onBlur={formik.handleBlur}
                        value={formik.values.zip_code}
                        onChange={formik.handleChange}
                        name='zip_code'
                        label='Zip Code'
                        placeholder='12345'
                        className='mb-2 light'
                        required={true}
                        error={formik.touched.zip_code && formik.errors.zip_code}
                        style={{ width: 111 }}
                    />
                </div>
                <div className='mt-5'>
                    <button className='btn' type='submit'>
                        Save Changes
                    </button>
                    <button className='btn--outline ms-4' type='button'>
                        Cancel
                    </button>
                </div>
            </form>
        </div>
    );
}
