import './Footer.scss';
import { RoutesEnum } from '../../core/enums'
import { Link } from 'react-router-dom'

export default function Footer() {
  return (
    <div className='Footer text-end'>
      <div className='mb-2'>© Lede AI 2023</div>
      <div className='mb-2'><a className='text-white' target='_blank' href="https://forms.monday.com/forms/503c66bbb4c3ad12da197b2e87293cf3?r=use1" rel="noreferrer">Help</a></div>
      <div className="mb-2">
        <Link className='text-white' to={RoutesEnum.TermsConditions}>Terms</Link>
      </div>
    </div>
  );
}
