import ReactQuill, { contextType } from "react-quill";
import "react-quill/dist/quill.snow.css";
import "./RichTextEditor.scss";

interface Props {
  text: string;
  onChange: any;
  quillRef: any;
}

export default function RichTextEditor({ text, onChange, quillRef }: Props) {
  return (
    <ReactQuill
      theme="snow"
      value={text}
      onChange={(content, delta, source, editor: any) => {
        onChange(content);
      }}
      preserveWhitespace
      ref={quillRef}
      className="RichTextEditor"
    />
  );
}
