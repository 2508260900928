import Header from "../components/Header/Header";
import { RoutesEnum } from "../core/enums";
import { IRoute } from "../core/types";
import Creator from "../pages/Creator/Creator";
import PremiumCreator from "../pages/Creator/PremiumCreator";
import Dashboard from "../pages/Dashboard/Dashboard";
import { getUser } from "../core/api";
import Impact from "../pages/Impact/Impact";
import Page404 from "../pages/Page404";
import PreviousArticles from "../pages/PreviousArticles/PreviousArticles";
import Pricing from "../pages/Pricing/Pricing";
import Settings from "../pages/Settings/Settings";
import TermsConditions from "../pages/Other/TermsConditions";
import { useAppSelector } from '../hooks/useReduxToolkit'
import Routes from "../Routes";
import "./DashboardContainer.scss";

const routes: IRoute[] = [
  {
    path: "/",
    component: Dashboard,
    exact: true,
  },
  {
    path: RoutesEnum.Creator,
    component: Creator,
  },
  {
    path: RoutesEnum.PremiumCreator,
    component: PremiumCreator,
  },
  {
    path: RoutesEnum.PreviousArticles,
    component: PreviousArticles,
  },
  {
    path: RoutesEnum.Impact,
    component: Impact,
  },
  {
    path: RoutesEnum.Pricing,
    component: Pricing,
  },
  {
    path: RoutesEnum.Settings,
    component: Settings,
  },
  {
    path: RoutesEnum.TermsConditions,
    component: TermsConditions,
  },
  {
    path: "*",
    redirectTo: "/404",
    component: Page404,
  },
];

export default function DashboardContainer() {
  const userData = useAppSelector(s => s.user.user)
  let subState = null
  let bannerText = ''
  let buttonText = 'Billing'
  let billingUrl = ''

  if (userData){
    subState = userData.subscription_status
    if (subState === 'trialing'){
      billingUrl = process.env.REACT_APP_STRIPE_BILLING_URL !== undefined ? process.env.REACT_APP_STRIPE_BILLING_URL : billingUrl
      bannerText = "Your trial period will end in " + userData.trial_days_left +  " days."
    }
    else if (subState === 'inactive' || subState === 'canceled'){
      bannerText = "Your subscription has been deactivated. Reactivate your subscription here"
    }
    else if (subState === 'notvalid'){
      bannerText = "Upgrade to use generative AI for custom templates, to fix formatting errors, and more."
      billingUrl = process.env.REACT_APP_STRIPE_URL !== undefined ? process.env.REACT_APP_STRIPE_URL : billingUrl
      buttonText = 'Start Free Trial'
    }
  }
  
  return (

    <div className="flex-grow-1 DashboardContainer d-flex flex-column"> 
    {( () => {
      switch (subState){
        case 'trialing':
        case 'inactive':
        case 'canceled':
        case 'notvalid':
          return <Header bannerText={bannerText} bannerVisible={true} bannerButtonText={buttonText} billingUrl={billingUrl} />;
        default: 
          return <Header bannerText="" bannerVisible={false} />
        }
      })()}

      <div className="flex-grow-1 d-flex flex-column">
        <Routes routes={routes} />
      </div>

    </div>
  );
}
