import { ReactComponent as CheckIcon } from "../../icons/check.svg";
import './Pricing.scss';

const PLANS = [
    {
        name: 'Starter',
        price: 29,
        points: [
            'Pre-made templates',
            'English Only',
            'Limit 2 exports/mo',
        ],
    },
    {
        name: 'Growth',
        price: 89,
        points: [
            'Up to 10 custom templates',
            'BYO language',
            'Saved export history',
            '10 exports/mo',
        ],
    },
    {
        name: 'Pro',
        price: 199,
        points: [
            'Unlimited templates',
            'Untitled exports',
            'In-app translation',
            'Export to audio',
            'API access',
        ],
    }
]

export default function Pricing() {

    return (
        <div className="pricing">
            <div className="text--orange fw-bold text-center">Pricing</div>
            <div className="Dashboard__title fw-bold text-center" >
                Get the most out of your plan
            </div>
            <div className="pricing-desc">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod <br /> tempor incididunt ut labore et dolore magn.</div>
            <div className="plan-card-wrap">
                {
                    PLANS.map((plan, index) =>
                        <div className={'plan-card ' + (index === 1 ? 'active' : '')}>
                            <h3 className="plan-title">{plan.name}</h3>
                            <div className="plan-price">
                                <div className="plan-price-digit">${plan.price}</div>
                                <div className="plan-price-text">/per-user</div>
                            </div>
                            <button className="btn w-100 mt-4 mb-2">Get Started</button>
                            <ul className="plan-points">
                                {
                                    plan.points.map(point =>
                                        <li><CheckIcon className="me-2" />{point}</li>
                                    )
                                }
                            </ul>
                        </div>
                    )
                }
            </div>
        </div>
    );
}
