import gsap from 'gsap';
import { useEffect, useRef, useState } from 'react';

interface Props {
  value: number;
  snapValue?: number;
}

export default function AnimatedNumber({ value, snapValue }: Props) {
  const ref = useRef(null);
  const isViewed = useRef(false);
  const [state, setState] = useState({ value: 0 });

  const target = {
    value: state.value,
  };

  useEffect(() => {
    if (ref.current) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !isViewed.current) {
              gsap.to(target, {
                value,
                duration: 1.5,
                ease: 'power2.out',
                snap: snapValue ? { value: snapValue } : 'value',
                onUpdate() {
                  setState({ value: target.value });
                },
              });
              isViewed.current = true;
            }
          });
        },
        {
          root: null,
          rootMargin: '0px',
          threshold: 1.0,
        }
      );

      observer.observe(ref.current);
      return () => {
        observer.disconnect();
      };
    }

    // eslint-disable-next-line
  }, []);
  return <span ref={ref}>{state.value}</span>;
}
