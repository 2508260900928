import axios, { AxiosResponse } from "axios";
import jwtdecode from "jwt-decode";
import {
  ContentItem,
  CreatorConfig,
  GenerateContentInput,
  ParsedDataField,
  PostPreviewInput,
  PreviewData,
  FinishedAsset,
} from "../pages/Creator/Creator";
import { RegisterInput } from "../pages/SignUp/SignUp";

import {
  ACCESS_TOKEN_KEY,
  ID_TOKEN_KEY,
  isTokenInvalid,
  refreshAccessToken,
  REFRESH_TOKEN_KEY,
  removeTokens,
} from "./auth";
import { getEmailFromToken } from "./helpers";
import { normalizeImpact, normalizePost } from "./normalization";
import { Post } from "./types";
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";

// axios.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   return config;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });

// Add a response interceptor
// axios.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     // Do something with response data
//     return response;
//   },
//   function (error) {
//     return Promise.reject(error);
//   }
// );

export const refreshTokens = async () => {
  try {
    const res = await refreshAccessToken(
      localStorage.getItem(REFRESH_TOKEN_KEY) || ""
    );
    localStorage.setItem(
      ACCESS_TOKEN_KEY,
      res.AuthenticationResult!.AccessToken!
    );
    localStorage.setItem(ID_TOKEN_KEY, res.AuthenticationResult!.IdToken!);
  } catch (error) {
    removeTokens();
  }
};

axios.interceptors.request.use(
  async function (config) {
    const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY) || "";

    if (!accessToken) {
      return config;
    }

    if (isTokenInvalid(accessToken)) {
      await refreshTokens();
    }
    if (axios.defaults.headers != null) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`;
      axios.defaults.headers.common['id-token'] = localStorage.getItem(ID_TOKEN_KEY);
    }

    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export const getPreviousArticles = async () => {
  const decodedToken: { email: string } = jwtdecode(
    localStorage.getItem(ID_TOKEN_KEY)!
  );
  const { email } = decodedToken;
  // Get the user's timezone
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const res = await axios.get<Post[]>(`${API_URL}/publishing-history`, {
    params: {
      user_id: email,
      timezone: userTimeZone
    },
  });
  return res.data.map(normalizePost);
};

export const register = async (
  data: Omit<RegisterInput, "confirm_password">
) => {
  const res = await axios.post(`${API_URL}/register`, data);
  return res.data;
};

export const confirmAccount = async (
  data: any
) => {
    const res = await axios.post(`${API_URL}/confirm-account`, data);
    return res.data;
};

export const getUser = async () => {
  const headers = { Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_KEY)}`, 'id-token': localStorage.getItem(ID_TOKEN_KEY) };
  const res = await axios.get(`${API_URL}/get-user-data`, { headers });
  return res.data;
};

export const getCreatorCategories = async () => {
  const res = await axios.get<string[]>(`${API_URL}/creator/categories`);
  return res.data;
};

export const getCreatorConfig = async (category: string) => {
  const res = await axios.get<CreatorConfig>(
    `${API_URL}/creator/configs/${category}`
  );
  return res.data;
};

export const getUserContent = async (category: string) => {
  const res = await axios.get<any>(
    `${API_URL}/creator/usercontent/${category}`
  );
  return res.data;
};

export const postAiPreviewData = async (data: any) => {
  const res = await axios.post<
    any,
    AxiosResponse<{
      preview_data: PreviewData[];
      mapped_data: any;
    }>
  >(`${API_URL}/creator/generate-preview-ai`, data);
  return res.data;
}

export const postFile = async (file: File, content_name: string) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("content_name", content_name);
  const res = await axios.post<
    FormData,
    AxiosResponse<{
      items: { [key: string]: string | number | null }[];
      data_map: { [key: string]: string };
    }>
  >(`${API_URL}/creator/upload-file`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const postFilePremium = async (file: File, content_name: string) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("content_name", content_name);
  const res = await axios.post<
    FormData,
    AxiosResponse<{
      items: { [key: string]: string | number | null }[];
      data_map: { [key: string]: string };
      preview_data: PreviewData[];
    }>
  >(`${API_URL}/creator/premium-upload-file`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return res.data;
};

export const getImpact = async () => {
  const res = await axios.get(`${API_URL}/impact`, {
    params: {
      user_id: getEmailFromToken(),
      company_name: "Teammate AI",
    },
  });
  return normalizeImpact(res.data);
};

export const postPreview = async (data: PostPreviewInput) => {
  const res = await axios.post<
    PostPreviewInput,
    AxiosResponse<{
      preview_data: PreviewData[];
      mapped_data: ParsedDataField[];
    }>
  >(`${API_URL}/creator/generate-preview`, data);
  return res.data;
};

export const postGenerateContent = async (data: GenerateContentInput) => {
  const res = await axios.post<
    GenerateContentInput,
    AxiosResponse<ContentItem[]>
  >(`${API_URL}/creator/generate-content`, data);
  return res.data;
};

export const postCustomTemplate = async (data: any) => {
  const res = await axios.post<
    any,
    AxiosResponse<ContentItem[]>
  >(`${API_URL}/creator/generate-ai-content`, data);
  return res.data;
};

export const postSavedFinishedContent = async (data: FinishedAsset) => {
  const res = await axios.post(`${API_URL}/finished_asset`, data);
  return res.data;
};
