import { FormikErrors, useFormik } from 'formik';
import { useState } from 'react';
import Input from '../../components/Input/Input';
import { isEmail } from '../../core/helpers';
import { useAppSelector } from '../../hooks/useReduxToolkit';

export interface AccountInput {
    name: string;
    family_name: string;
    email: string;
    company_name: string;
}

export default function AccountSetting() {
    const user = useAppSelector((s) => s.user.user);
    const [isEditing, setIsEditing] = useState(false)
    const formik = useFormik<AccountInput>({
        initialValues: {
            name: user?.first_name || '',
            family_name: user?.last_name || '',
            email: user?.email || '',
            company_name: user?.company_name || '',
        },
        validate: (values) => {
            const errors: FormikErrors<AccountInput> = {};

            if (!values.name) {
                errors.name = 'Required';
            }
            if (!values.family_name) {
                errors.family_name = 'Required';
            }
            if (!values.company_name) {
                errors.company_name = 'Required';
            }
            if (!values.email) {
                errors.email = 'Required';
            }

            if (values.email && !isEmail(values.email)) {
                errors.email = 'Email not valid';
            }

            return errors;
        },
        onSubmit: (values) => {

        },
    });
    return (
        <div>
            <form className='w-100 ' onSubmit={formik.handleSubmit}>
                <Input
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    name='name'
                    label='First Name'
                    className='mb-4 light'
                    readOnly={!isEditing}
                    error={formik.touched.name && formik.errors.name}
                />
                <Input
                    onBlur={formik.handleBlur}
                    value={formik.values.family_name}
                    onChange={formik.handleChange}
                    name='family_name'
                    label='Last Name'
                    className='mb-4 light'
                    readOnly={!isEditing}
                    error={formik.touched.family_name && formik.errors.family_name}
                />
                <Input
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    name='email'
                    label='Email'
                    className='mb-4 light'
                    readOnly={!isEditing}
                    error={formik.touched.email && formik.errors.email}
                />
                <Input
                    onBlur={formik.handleBlur}
                    value={formik.values.company_name}
                    onChange={formik.handleChange}
                    name='company_name'
                    label='Company Name'
                    className='mb-4 light'
                    readOnly={!isEditing}
                    error={formik.touched.company_name && formik.errors.company_name}
                />
                <div className="fw-bold mb-2">Account Permissions - Member</div>
                <p>Contact your admin to upgrade your status from member to admin</p>
                <div className='mt-5'>
                    {
                        isEditing ?
                            <>
                                <button className='btn' type='submit'>
                                    Save Changes
                                </button>
                                <button className='btn--outline ms-4' onClick={() => setIsEditing(false)}>
                                    Cancel
                                </button>
                            </> :
                            <button className='btn--outline' onClick={() => setIsEditing(true)}>
                                Edit info
                            </button>
                    }
                </div>
            </form>
        </div>
    );
}
