import classNames from 'classnames';
import { useDrag, useDrop } from 'react-dnd';
import { ReactComponent as Tip } from '../../icons/green-tip.svg';
import { NormalizedField } from './Creator';

interface Props {
  field: NormalizedField;
  onDrop: (item: NormalizedField) => void;
  imported?: boolean;
}

export default function DragableField({
  field,
  onDrop,
  imported = false,
}: Props) {
  const [, drop] = useDrop(
    () => ({
      accept: 'field',
      drop: (item: NormalizedField) => {
        if (onDrop) onDrop(item);
      },
    }),
    [field]
  );

  const [, drag] = useDrag(
    () => ({
      type: 'field',
      item: field,
    }),
    [field]
  );

  if (imported) {
    return (
      <div
        className='DataMappingStep__box d-flex flex-column justify-content-center'
        ref={(node) => drag(drop(node))}
      >
        <div className='d-flex justify-content-between'>
          <div>
            <div className='text-dark fw-bold'>{field.initialLabel}</div>
            <div className='text--light DataMappingStep__value'>
              {field.value}
            </div>
          </div>
          <Tip />
        </div>
      </div>
    );
  }

  return (
    <div
      className={classNames(
        'DataMappingStep__box DataMappingStep__box--gray d-flex flex-column justify-content-center'
      )}
      ref={(node) => drag(drop(node))}
    >
      <div className='d-flex justify-content-between'>
        <div>
          <div className='text-dark fw-bold'>{field.initialLabel}</div>
          <div className='text--light DataMappingStep__value'>
            {field.value}
          </div>
        </div>
      </div>
    </div>
  );
}
