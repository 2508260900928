import { useDrop } from 'react-dnd';
import { ReactComponent as Cross } from '../../icons/red-cross.svg';
import { NormalizedField } from './Creator';
interface Props {
  onDrop: (item: NormalizedField) => void;
}

export default function DropBox({ onDrop }: Props) {
  const [, drop] = useDrop(() => ({
    accept: 'field',
    drop: (item: NormalizedField) => {
      onDrop(item);
    },
  }));

  return (
    <div
      className='DataMappingStep__box DataMappingStep__box--gray d-flex flex-column justify-content-center'
      ref={drop}
    >
      <div className='d-flex justify-content-between'>
        <div className='text--light fst-italic'>Drag and drop field here</div>
        <Cross />
      </div>
    </div>
  );
}
