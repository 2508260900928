import './Preview.scss';
import RichTextEditor from "../../components/RichTextEditor/RichTextEditor1";
import { Link } from 'react-router-dom';
import Modal from "../../components/Modal1";
import styled from "styled-components";
import { useModal } from "../../hooks/useModal";
import { useRef, useState, useEffect } from "react";
import { useAppSelector } from "../../hooks/useReduxToolkit";
import { ReactComponent as ArrowLeft } from '../../icons/arrow-left.svg';
import { ReactComponent as Edit } from '../../icons/edit.svg';
import { postAiPreviewData } from "../../core/api";
import { toast } from "react-toastify";
import { PreviewData } from './Creator';
import Loader from "../../components/Loader/Loader";

interface Props {
  data: PreviewData[];
  mappedData: any;
  onChangeMappedData: (value: any) => void;
  onChangePreviewData: (value: any) => void;
  onClickNext: () => void;
  onClickBack: () => void;
}

export default function Preview({ data, mappedData, onChangeMappedData, onChangePreviewData, onClickNext, onClickBack }: Props) {

  const user = useAppSelector((s) => s.user.user);
  const [isLoading, setIsLoading] = useState(false);
  const { isModalOpened, closeModal, openModal } = useModal();
  const [previewData, setPreviewData] = useState(data);
  const [generateButtonStatus, setGenerateButtonStatus ] = useState(false);
  const [text, onChangeText] = useState("");
  const [editingColumn, setEditingColumn] = useState("")
  const [editingValue, setEditingValue] = useState("")
  const [counters, setCounters] = useState<{ [key: string]: number }>({});
  const [premiumUser, setPremiumUser] = useState(false);

  useEffect(() => {
    if (user && ( user?.subscription_status === 'trialing' ||  user?.subscription_status === 'active')) {
      setPremiumUser(true)
    }
    else {
      setPremiumUser(false)
    }
  }, [user])

  useEffect(() => {
      // This effect updates the count for the current editingColumn value
      setCounters(prevCounters => ({
          ...prevCounters,
          [editingColumn]: (prevCounters[editingColumn] || 0) + 1
      }));
  }, [editingColumn]); // Runs when editingColumn changes


  const handleChangeofText = (value:string) =>
  {
    if (value.length > 4){
      setGenerateButtonStatus(true);
    }
    else{
      setGenerateButtonStatus(false);
    }
    onChangeText(value);
  }

  const generatePreviewData = () =>
  {
    setIsLoading(true);
    postAiPreviewData({
      'editcolumn': editingColumn,
      'originalvalue': editingValue,
      'editedvalue': text,
      'items': previewData,
      'mappedData': mappedData
    }).then((res) =>{
      setPreviewData(res.preview_data)
      onChangePreviewData(res.preview_data)
      onChangeMappedData(res.mapped_data)
    })
    .catch((err) => {
      let errMsg = err.message;
      if (err.response?.data){
          errMsg = err.response.data.message;
      }
      toast.error(errMsg, {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
      });
    })
    .finally(() => {
      setIsLoading(false);
    }
    )
  }

  const quillRef:any = useRef(null);

  return (

    <div className='Preview'>
        {isLoading && <Loader />}
      <div className='text--primary mb-4 pb-2'>
      Preview your data here.  If the data doesn’t look right, either return back and re-match the column headers or upload a new file.
      </div>
      <div
        style={{
          marginBottom: 56,
        }}
      >
        <div className='table-responsive Scrollbar'>
          <table className='table mb-4'>
            <tbody>
              {previewData.map((c) => {
                return (
                  <tr key={c.column}>
                    <td className='text-nowrap fw-bold p-3 text--dark'>
                      { premiumUser && 
                        <Edit onClick={() =>{
                        setEditingColumn(c.column)
                        setEditingValue(c.values[0] ? c.values[0].toString() : "")
                        openModal()
                      }}
                      style={{
                        marginRight: 10,
                        pointerEvents: counters[c.column] > 3 ? 'none' : 'auto'
                      }}
                       />
                      }

                      {c.column}
                    </td>
                    {c.values.map((f, n) => (
                      <td key={n} className='text-nowrap p-3 text--primary'>
                        {f}
                      </td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-between'>
        <button
          className='Creator__back p-0 bg-transparent border-0 fw-bold text--primary'
          onClick={onClickBack}
        >
          <ArrowLeft className='me-2' />
          Back
        </button>
        <div className='d-flex align-items-center flex-grow-1 justify-content-end'>
          <Link className='Creator__cancel fw-bold opacity-50' to={'/'}>
            Cancel Import
          </Link>
          <button
            className='btn w-100 Creator__btn ms-4'
            type='submit'
            onClick={onClickNext}
          >
            Generate!
          </button>
        </div>
      </div>
      <div className='custom-modal'>
      <Modal isOpened={isModalOpened} closeModal={closeModal}>
        <ModalTitle className="fw-bold text-center mb-2">
          Custom Formatting
        </ModalTitle>
        <div className="text-dark text-center mb-5">
        Tell us what you want the formatting to be and we will use generative AI to update the formatting for all similar items.
        </div>
      <div className="mb-4 pb-2 custom-light-container">
        <div className='custom-light-div text-center'>
        <span className="fw-bold mb-2 custom-title" >Uploaded Value</span>
        <div className='custom-value'>
        <span className="mb-2">{editingValue}</span>
        </div>

        </div>
        <div className='custom-light-div text-center mb-4 pb-2'>
        <span className="mb-2 custom-title">Desired Formatting</span>
          <div className='custom-post-box'>
          <RichTextEditor 
            text={text} 
            onChange={handleChangeofText}
            quillRef={quillRef}
            />
            </div>
        </div>

      </div>
        <div className="text-center mt-n2">
          <button
            style={{
              width: 184,
              marginBottom: 20
            }}
            className="btn btn--orange"
            disabled={!generateButtonStatus}
            onClick={() => {
              generatePreviewData();
              closeModal();
              setEditingColumn("");
              setEditingValue("");
              setGenerateButtonStatus(false);
            }}
          >
            Generate
          </button>
        </div>
        <div className="text-center mb-5">
          Although we do our best, generative AI technology is experimental and is prone to mistakes from time to time.
        </div>
      </Modal>
      </div>
    </div>
  );
}

const ModalTitle = styled.div`
  font-size: 32px;
  line-height: 48px;
`;