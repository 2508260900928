import './ForgotPassword.scss';
import { FormikErrors, useFormik } from 'formik';
import axios from "axios";
import Input from '../../components/Input/Input';
import LoginContainer from '../../containers/LoginContainer';
import { isEmail } from '../../core/helpers';
import { Link, useHistory } from 'react-router-dom'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { RoutesEnum } from '../../core/enums'

interface FormikValues {
  password: string
  confirm_password: string
  email: string
  code: string
}

export default function ForgotPassword() {
  const history = useHistory()
  const [otpSent, setOtpSent] = useState(false)
  const [loading, setLoading] = useState(false)
  const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      confirm_password: '',
      code: ''
    },
    validate: (values) => {
      const errors: FormikErrors<FormikValues> = {}
      if (!otpSent) {
        if (values.email === '') {
          errors.email = 'Required'
        }
        if (values.email && !isEmail(values.email)) {
          errors.email = 'Email not valid';
        }
      }
      if (otpSent) {
        if (values.confirm_password === '') {
          errors.confirm_password = 'Required'
        }
        if (values.password === '') {
          errors.password = 'Required'
        }
        if (values.confirm_password !== values.password) {
          errors.confirm_password = 'Password does not match'
        }
        if (values.code === '') {
          errors.code = 'Required'
        }
      }
      return errors
    },
    onSubmit: (values) => {
      if (!otpSent) {
        const payload = {
          email: values.email
        }
        setLoading(true)
        axios.post(`${API_URL}/forgot-password`, payload).then((res) => {

        }).then(() => {
          toast.success('OTP Sent Successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          })
          setOtpSent(true)
        }).catch(e => {
          toast.error(e.message, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          })
        }).finally(() => {
          setLoading(false)
        })
      }
      if (otpSent) {
        const payload = {
          email: values.email,
          code: values.code,
          password: values.password
        }
        setLoading(true)
        axios.post(`${API_URL}/confirm-forgot-password`, payload).then((res) => {
        }).then(() => {
          toast.success('Password Reset Successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark'
          })
          history.push('/login')
        })
          .catch((e) => {
            toast.error('Incorrect OTP!', {
              position: 'top-right',
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark'
            })
          })
          .finally(() => {
            setLoading(false)
          })
      }
    }
  })

  return (
    <LoginContainer>
      <div className='ForgotPassword__card Login__card px-3 d-flex flex-column align-items-center justify-content-center w-100'>
        <div className='Login__title fw-bold mb-2'>Forgot Password?</div>
        <div className='mb--50'>Welcome! Let's get started.</div>
        <form className='w-100 Login__form ' onSubmit={formik.handleSubmit}>
            <div className='d-flex flex-column '>
              {!otpSent &&
                <Input
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  name='email'
                  label='Email'
                  className='mb-4 flex-grow-1'
                  required
                  readOnly={otpSent}
                  error={formik.touched.email}
                />}
              {otpSent && <>
                <Input
                    onBlur={formik.handleBlur}
                    value={formik.values.code}
                    onChange={formik.handleChange}
                    name='code'
                    label='OTP'
                    className='mb-4 flex-grow-1'
                    type='text'
                    required

                    error={formik.touched.password}
                  />
                <Input
                  onBlur={formik.handleBlur}
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  name='password'
                  label='New Password'
                  className='mb-4 flex-grow-1'
                  type='password'
                  required
                  error={formik.touched.password}
                />
                <Input
                  onBlur={formik.handleBlur}
                  value={formik.values.confirm_password}
                  onChange={formik.handleChange}
                  name='confirm_password'
                  label='Confirm New Password'
                  className='mb-5'
                  type='password'
                  required
                  error={formik.touched.confirm_password}
                />
              </>
              }
            </div>
          <div className='d-flex flex-column align-items-center justify-content-center'>
            <button className='btn w-100 Login__btn mb-3' type='submit'>
              Reset
              </button>
              <div className="text-end fw-bold mt-1 mb-1">
              <Link to='/'>Return to login</Link>
            </div>
            <div>
              New User?{' '}
              <Link to={RoutesEnum.SignUp} className="fw-bold">
                Sign Up!
              </Link>
            </div>
          </div>
        </form>
      </div>
    </LoginContainer>
  );
}
