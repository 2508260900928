import "./Dashboard.scss";
import { ReactComponent as BirthIcon } from "../../icons/birth.svg";
import { ReactComponent as CrimeIcon } from "../../icons/crime.svg";
import { ReactComponent as MarriageIcon } from "../../icons/mirrage.svg";
import { ReactComponent as ObituariesIcon } from "../../icons/obituaries.svg";
import { ReactComponent as RealEstateIcon } from "../../icons/real-estate.svg";
import { ReactComponent as SportsIcon } from "../../icons/sports.svg";
import { ReactComponent as WeatherIcon } from "../../icons/weather.svg";
import { ReactComponent as CustomIcon } from "../../icons/custom.svg";
import { Link } from "react-router-dom";
import { RoutesEnum } from "../../core/enums";
import { getCreatorCategories } from "../../core/api";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../hooks/useReduxToolkit";
import Loader from "../../components/Loader/Loader";

export const ITEMS_MAP: {
  [key: string]: { icon: JSX.Element; label: string; className?: string };
} = {
  sports: { icon: <SportsIcon />, label: "Sports" },
  real_estate: { icon: <RealEstateIcon />, label: "Real Estate" },
  weather: { icon: <WeatherIcon />, label: "Weather" },
  marriage_certificates: {
    icon: <MarriageIcon />,
    label: "Marriage Certificates",
  },
  births: { icon: <BirthIcon />, label: "Birth Announcements" },
  death_notice: { icon: <ObituariesIcon />, label: "Death Notice" },
  crime: { icon: <CrimeIcon />, label: "Crime", className: "Dashboard__crime" },
  custom: {icon: <CustomIcon />, label: "Custom"}
};

export default function Dashboard() {
  const user = useAppSelector((s) => s.user.user);
  const [premiumUser, setPremiumUser] = useState(false);
  const [categories, setCategories] = useState<string[]>([]);
  const [isCategoryLoading, setCategoryLoading] = useState(true);

  let { data: datacategories, isLoading } = useQuery(
    ["Categories", user!.user_id],
    getCreatorCategories
  );
  
  useEffect(() => {
    if (user && ( user?.subscription_status === 'trialing' ||  user?.subscription_status === 'active')) {
      setPremiumUser(true)
    }
    else {
      setPremiumUser(false)
    }
  }, [user])

  useEffect(() => {
    if (datacategories && datacategories.length > 0) {
      let filteredCategories = datacategories;
      if (!premiumUser) {
        filteredCategories = datacategories.filter(category => category !== 'custom');
      }
      setCategories(filteredCategories);
      setCategoryLoading(false);
    }
  }, [datacategories, premiumUser]); // Include premiumUser in dependencies array too


  if (isLoading) return <Loader />;

  if (isCategoryLoading) return <Loader />;

  return (
    <div className="Dashboard text-center">
      <div className="text--orange fw-bold mb--5">Content Hub</div>
      <div className="Dashboard__title fw-bold mb-2">
        Let’s Create Some Content!
      </div>
      <div
        style={{
          maxWidth: 630,
          marginBottom: 113,
        }}
        className="mx-auto"
      >
        Start by selecting a category below.
      </div>
      <div className="row row-cols-auto justify-content-center mb--50">
        {categories &&
          categories.slice(0, 2).map((item) => (
            <div className="col" key={item}>
              <Link
                to={premiumUser && item === 'custom' ? RoutesEnum.PremiumCreator.replace(":id", item) : RoutesEnum.Creator.replace(":id", item)}
                className={`Dashboard__item d-block ${
                  ITEMS_MAP[item]?.className || ""
                }`}
              >
                {ITEMS_MAP[item]?.icon}
                <div className="fw-bold">{ITEMS_MAP[item]?.label || item}</div>
              </Link>
            </div>
          ))}
      </div>
      <div className="row row-cols-auto justify-content-center mb--50">
        {categories &&
          categories.slice(2, 5).map((item) => (
            <div className="col" key={item}>
              <Link
                to={premiumUser && item === 'custom' ? RoutesEnum.PremiumCreator.replace(":id", item) : RoutesEnum.Creator.replace(":id", item)}
                className={`Dashboard__item d-block ${
                  ITEMS_MAP[item]?.className || ""
                }`}
              >
                {ITEMS_MAP[item]?.icon}
                <div className="fw-bold">{ITEMS_MAP[item]?.label || item}</div>
              </Link>
            </div>
          ))}
      </div>
      <div className="row row-cols-auto justify-content-center mb--50">
        {categories &&
          categories.slice(5, 7).map((item) => (
            <div className="col" key={item}>
              <Link
                to={premiumUser && item === 'custom' ? RoutesEnum.PremiumCreator.replace(":id", item) : RoutesEnum.Creator.replace(":id", item)}
                className={`Dashboard__item d-block ${
                  ITEMS_MAP[item]?.className || ""
                }`}
              >
                {ITEMS_MAP[item]?.icon}
                <div className="fw-bold">{ITEMS_MAP[item]?.label || item}</div>
              </Link>
            </div>
          ))}
      </div>
      {categories && categories.length > 7 && (
        <div className="row justify-content-center mb--50 Dashboard__lastRow">
          {categories.slice(7).map((item) => (
            <div className="col" key={item}>
              <Link
                to={premiumUser ? RoutesEnum.PremiumCreator.replace(":id", item) : RoutesEnum.Creator.replace(":id", item)}
                className={`Dashboard__item d-block ${
                  ITEMS_MAP[item]?.className || ""
                }`}
              >
                {ITEMS_MAP[item]?.icon}
                <div className="fw-bold">{ITEMS_MAP[item]?.label || item}</div>
              </Link>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
