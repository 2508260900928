import "./Creator.scss";
import classNames from "classnames";
import { useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { ITEMS_MAP } from "../Dashboard/Dashboard";
import { ReactComponent as ArrowRightIcon } from "../../icons/arrow-right.svg";
import { ReactComponent as CloseIcon } from "../../icons/close.svg";
import UploadStep from "./UploadStep";
import DataMappingStep from "./DataMappingStep";
import GenerateStep from "./GenerateStep";
import {
  getCreatorConfig,
  postPreview,
  postFile,
  postGenerateContent,
  postSavedFinishedContent,
  postCustomTemplate,
  getUserContent
} from "../../core/api";
import Loader from "../../components/Loader/Loader";
import { useAppSelector } from "../../hooks/useReduxToolkit";
import { useQuery } from "react-query";
import Preview from "./Preview";
import { toast } from "react-toastify";
import { getEmailFromToken } from "../../core/helpers";
import CustomTemplates from "./CustomTemplate";

export interface CreatorConfig {
  content_name: string;
  content_templates: any[];
  data_display_text: string;
  data_merge_text: string;
  required_fields: string[];
  required_fields_sample_data: string;
  upload_sidebar_text: string;
  version: string;
}

export interface DataMapingInput {
  [key: string]: string;
}

export interface PostPreviewInput {
  mapping: DataMapingInput;
  data: ParsedDataField[];
}

const STEPS = [
  {
    id: 1,
    label: "Upload File",
  },
  {
    id: 2,
    label: "Data Mapping",
  },
  {
    id: 3,
    label: "Preview Data",
  },
  {
    id: 4,
    label: "Generate & Finish",
  }
];

export interface PreviewData {
  column: string;
  values: (string | number | null)[];
}

export interface ParsedDataField {
  [key: string]: string | number | null;
}

export interface Field {
  label: string;
  value: string | number | null;
}

export interface NormalizedField extends Field {
  initialLabel: string;
}

export interface ImportedState {
  [key: string]: NormalizedField;
}

export interface GenerateContentInput {
  items: ParsedDataField[];
  content_name: string;
}

export interface ContentItem {
  content: string;
  content_dynamics: number;
}

export interface FinishedAsset {
  user_id: string;
  asset_id: string;
  generated_asset_body: any;
  finished_asset_body: any;
  company_name: string;
  content_type: string;
}

export interface CustomTemplateInput {
  template: string;
  content: string;
  items: ParsedDataField;
  category: string;
}

export default function Creator() {
  const user = useAppSelector((s) => s.user.user);
  const { id } = useParams<{ id: string }>();
  const history = useHistory();

  const [previewData, setPreviewData] = useState<PreviewData[]>([]);
  const [activeStep, setActiveStep] = useState(1);
  const [imported, setImported] = useState<ImportedState>({});
  const [notImported, setNotImported] = useState<NormalizedField[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [parsedData, setParsedData] = useState<ParsedDataField[]>([]);
  const [mappedData, setMappedData] = useState<ParsedDataField[]>([]);
  const [content, setContent] = useState<ContentItem[]>([]);
  const [editedContent, setEditedContent] = useState("");
  const [customTemplate, setCustomTemplate] = useState("");
  const [customTempClickCount, setCustomTempClickCount] = useState(1);
  const [customTempGenerateButtonStatus, setCustomTempGenerateButtonStatus] = useState(false);

  // console.log(
  //   "🚀 ~ file: Creator.tsx:119 ~ Creator ~ editedContent",
  //   editedContent
  // );
  const [currentDynamic, setCurrentDynamic] = useState<number>();

  const dynamics = useMemo(() => {
    return content
      .map((c) => c.content_dynamics)
      .filter((c, i, self) => self.indexOf(c) === i)
      .sort((a, b) => a - b);
  }, [content]);

  useEffect(() => {
    setCurrentDynamic(dynamics[0]);
  }, [dynamics]);


  useEffect(() => {
    getUserContent(id).then((res) => {
      setCustomTemplate(res.content);

      if (res.content.length > 50 ){
        setCustomTempGenerateButtonStatus(true)
      }
    })
    .catch((err) => {
      console.log("Error in fetching user content for category", err);
      setCustomTemplate("");
    });
  }, []);

  const [fields, setFields] = useState<Field[]>();
  const [initialMapping, setInitialMapping] = useState<{
    [key: string]: string;
  }>({});

  const [finalMapping, setFinalMapping] = useState<{
    [key: string]: string;
  }>({});

  const { data, isLoading: isLoadingConfig } = useQuery(
    ["CreatorConfig", user!.user_id, id],
    () => getCreatorConfig(id)
  );

  useMemo(() => {
    const notImportedFields: NormalizedField[] = [];

    const importedFields = (data?.required_fields || []).reduce(
      (acc: ImportedState, item) => {
        acc[item] = {
          value: "",
          label: item,
          initialLabel: "",
        };
        return acc;
      },
      {}
    );

    const mappingValues = (Object.values(initialMapping) || []).map((v) =>
      v.toLowerCase()
    );

    const mappingEntries = Object.entries(initialMapping) || [];

    const requiredFields = (data?.required_fields || []).map((v) =>
      v.toLowerCase()
    );

    (fields || []).forEach((f) => {
      if (mappingValues.includes(f.label.toLowerCase())) {
        const targetKey = mappingEntries.find((t) => {
          return t[1].toLowerCase() === f.label.toLowerCase();
        })![0];
        importedFields[targetKey] = {
          ...f,
          label: targetKey,
          initialLabel: f.label,
        };
      } else if (requiredFields.includes(f.label.toLowerCase())) {
        importedFields[f.label] = { ...f, initialLabel: f.label };
      } else {
        notImportedFields.push({ ...f, initialLabel: f.label, label: "" });
      }
    });
    setImported(importedFields);
    setNotImported(notImportedFields);
  }, [data, fields, initialMapping]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [activeStep]);

  const goNext = () => {
    if (customTempClickCount >= 4 && activeStep == 4)
    {
      toast.error("Maximum Retries. Please reupload the data to use Custom Templates again", {
        position: "top-right",
        autoClose: 4500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "dark",
      });
    }
    else{
      setActiveStep((prev) => prev + 1);
    }
  };
  const goBack = () => {
    setActiveStep((prev) => prev - 1);
  };

  const resetFlow = () => {
    setParsedData([]);
    setFields(undefined);
    setPreviewData([]);
    setMappedData([]);
    setActiveStep(1);
  };

  const finishAndSave = () => {
    postSavedFinishedContent({
      asset_id: id,
      content_type: id,
      user_id: getEmailFromToken(),
      generated_asset_body: content
        .filter((v) => v.content_dynamics <= currentDynamic!)
        .map((v) => v.content)
        .join("<br />"),
      finished_asset_body: editedContent,
      company_name: "LedeAI",
    })
      .then((res) => {
        setParsedData([]);
        setFields(undefined);
        setPreviewData([]);
        setMappedData([]);
        setActiveStep(1);
        setCustomTempClickCount(1);
        history.push("/");
      })
      .catch((err) => {
        console.log("🚀 ~ file: Creator.tsx:225 ~ finishAndSave ~ err", err);
      });
  };

  const onSubmitFile = (file: File) => {
    setIsLoading(true);
    setCustomTempClickCount(1);
    postFile(file, id)
      .then((res) => {
        console.log("🚀 ~ file: Creator.tsx:235 ~ .then ~ res", res);
        toast.success("File uploaded successfully!", {
          position: "top-right",
          autoClose: 4500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "dark",
        });
        setFields(
          Object.entries(res.items[0]).map(([k, v]) => ({
            label: k,
            value: v,
          }))
        );
        setParsedData(res.items);
        setInitialMapping(res.data_map);
        setIsLoading(false);
        goNext();
      })
      .catch((err) => {
        console.error(err);
        toast.error(err.message, {
          position: "top-right",
          autoClose: 4500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "dark",
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmitDataMapping = (config: ImportedState) => () => {
    const mapping = Object.entries(config)
      .map(([k, v]) => ({
        [k]: v.initialLabel,
      }))
      .reduce((acc: DataMapingInput, item) => {
        return { ...acc, ...item };
      }, {});

    setIsLoading(true);
    setFinalMapping(mapping);
    postPreview({ mapping, data: parsedData })
      .then((res) => {
        setPreviewData(res.preview_data);
        setMappedData(res.mapped_data);
        goNext();
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmitPreview = () => {
    setIsLoading(true);
    postGenerateContent({
      items: mappedData,
      content_name: id,
    })
      .then((res) => {
        setContent(res);
        goNext();
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const onSubmitCustomTemplates = () => {
    console.log(customTemplate);

      if ( content.length > 105 ){
        toast.error("Please upload data less than 100 items in the file.", {
          position: "top-right",
          autoClose: 4500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "dark",
        });
      }
      else{
        setIsLoading(true);
        setCustomTempClickCount((prev) => prev + 1)
        postCustomTemplate({
          items: mappedData,
          raw_data: parsedData,
          content: content,
          template: customTemplate,
          category: id
        })
          .then((res) => {
            setContent(res);
            goBack();
          })
          .catch((err) => {
            let errMsg = err.message;
            if (err.response?.data){
                errMsg = err.response.data.message;
            }
            toast.error(errMsg, {
              position: "top-right",
              autoClose: 4500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              theme: "dark",
            });
          })
          .finally(() => {
            setIsLoading(false);
          });
      }
  }

  const renderStep = () => {
    switch (activeStep) {
      case 1:
        return <UploadStep onSubmitFile={onSubmitFile} data={data!} id={id!} />;
      case 2:
          return (
            <DataMappingStep
              requiredFields={data?.required_fields || []}
              imported={imported}
              setImported={setImported}
              notImported={notImported}
              setNotImported={setNotImported}
              onClickNext={onSubmitDataMapping}
              onClickBack={goBack}
            />
          );
      case 3:
        return (
          <Preview
            data={previewData}
            mappedData={mappedData}
            onChangeMappedData={setMappedData}
            onChangePreviewData={setPreviewData}
            onClickNext={onSubmitPreview}
            onClickBack={goBack}
          />
        );
      case 4:
      default:
        return (
          <GenerateStep
            onClickBack={goBack}
            onClickFinish={finishAndSave}
            category={id}
            onClickStartOver={resetFlow}
            onClickCustomTemplates={goNext}
            data={content}
            updateEditedContent={(text) => setEditedContent(text)}
          />
        );
      case 5:
          return (
            <CustomTemplates
              onClickBack={goBack}
              allFields={fields}
              onClickStartOver={resetFlow}
              onClickGenerate={onSubmitCustomTemplates}
              text={customTemplate}
              onChangeText={setCustomTemplate}
              generateButtonStatus={customTempGenerateButtonStatus}
              setGenerateButtonStatus={setCustomTempGenerateButtonStatus}
            />
          );
    }
  };

  if (isLoadingConfig) return <Loader />;

  return (
    <div className="Creator flex-grow-1 d-flex flex-column">
      {isLoading && <Loader />}
      <div className="d-flex justify-content-between">
        <div
          className="Paper w-100 mx-auto"
          style={{
            paddingBottom: 100,
          }}
        >
          <div className="d-flex justify-content-between">
            {ITEMS_MAP[id]?.icon && (
              <div className="Creator__icon">{ITEMS_MAP[id].icon}</div>
            )}
            <div className="d-flex align-items-start">
              {STEPS.map((s, i, self) => (
                <div key={s.id}>
                  <div
                    className={classNames(
                      "Creator__step d-flex align-items-center",
                      {
                        "Creator__step--active": activeStep === s.id,
                      }
                    )}
                  >
                    <span className="fw-bold">Step {i + 1}:</span>&nbsp;
                    {s.label}
                    {i !== self.length - 1 && (
                      <div className="mx-2">
                        <ArrowRightIcon />
                      </div>
                    )}
                  </div>
                </div>
              ))}
              <Link className="Creator__close ms-4" to={"/"}>
                <CloseIcon />
              </Link>
            </div>
          </div>
          <div className="text--orange fw-bold">Article Creator</div>
          <div className="Creator__title fw-bold mb-4 pb-2">
            {ITEMS_MAP[id]?.label || id}
          </div>
          {data && renderStep()}
        </div>
      </div>
    </div>
  );
}

export interface PostFileInput {
  file_path: string;
  content_name: string;
  company_name?: string;
}

export interface CreatorFile extends PostFileInput {
  uuid: string;
}
